import { camelCase } from 'lodash';

import { SiteSearchFacets } from '@domain/search';

import { SolrFacetFieldOption, SolrFacetFields } from '@typings/operations';

export const mapSolrFacetFieldsToSiteSearchFacets = (
  facets: SolrFacetFields | SolrFacetFieldOption
) => {
  const modifiedFacets = {} as SiteSearchFacets;
  if (!facets) {
    return undefined;
  }

  Object.entries(facets).forEach(([key, value]) => {
    modifiedFacets[camelCase(key) as keyof SiteSearchFacets] = value;
  });

  return modifiedFacets;
};
